import request from '../utils/myaxios'

// 店铺列表
export function storeList(params) {
    return request({
        url: '/official/store/page',
        method: 'get',
        params
    })
}

// c端百货商店列表
export function departmentList(params) {
    return request({
        url: '/official/store/department',
        method: 'get',
        params
    })
}

// 店铺详情
export function storeDetail(id, isShow) {
    if (isShow == 'isShow') {
        return request({
            url: '/official/store/' + id,
            method: 'get',
            headers: { isShow: true }
        })
    } else {
        return request({
            url: '/official/store/' + id,
            method: 'get'
        })
    }
}

// 店铺推荐列表
export function storeRecommendList(params) {
    return request({
        url: '/official/store/list/recommend',
        method: 'get',
        params
    })
}

// 指定的地区及下级地区列表
export function getAreaList(params) {
    return request({
        url: '/official/store/specifyList',
        method: 'get',
        params
    })
}

// 附近的餐厅
export function getNearByStore(params) {
    return request({
        url: '/official/store/near_by',
        method: 'get',
        params
    })
}

// 批量查询指定的餐厅信息
export function getStoreLists(params) {
    return request({
        url: '/official/store/list',
        method: 'get',
        params
    })
}

// 行销列表
export function getMarketing(params) {
    return request({
        url: '/official/store/notice/page',
        method: 'get',
        params
    })
}
// banner-分类列表-广告位
export function getBanners(params) {
    return request({
        url: '/official/store/banner/page',
        method: 'get',
        params
    })
}